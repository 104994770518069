<template>
  <div class="client-products-details client-page">
    <div class="flex flex-center content-title">
      <span class="cursor-pointer" @click="goBack">
        <i class="el-icon-arrow-left"></i>
        返回
      </span>
      <div class="flex1 flex flex-center font-size-20">
        商品详情
      </div>
    </div>
    <div v-loading="loading" class="flex content-details">
      <div v-if="info.images && info.images.length" class="content-details-left">
        <el-carousel height="280px">
          <el-carousel-item v-for="item in info.images" :key="item">
            <el-image class="width-full height-full" :src="item" fit="cover" :preview-src-list="info.images"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else class="content-details-left">
        <el-image class="width-full" :src="imgDefault" fit="cover"></el-image>
      </div>
      <div class="content-details-right">
        <div class="flex flex-row-center">
          <el-tag v-if="false" type="warning">
            热销
          </el-tag>
          <div class="font-size-24">【{{ info.name }}】{{ item.code }}</div>
        </div>
        <div v-if="false" class="flex flex-row-center m-t-15 font-size-18">
          <span class="max-out">满减</span>
          <span class="m-l-10">满200减20</span>
          <el-button type="text" class="m-l-10 font-size-18" @click="drawDown('max-out')">领取</el-button>
          <span class="discount m-l-40">折扣</span>
          <span class="m-l-10">满200打8折</span>
          <el-button type="text" class="m-l-10 font-size-18" @click="drawDown('discount')">已领取</el-button>
          <div class="flex1"></div>
          <el-button type="text" class="font-size-18" @click="claimCenter">前往领劵中心领取>></el-button>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">价格：</span>
          <span class="text-price font-weight-bolder font-size-24">¥{{ info.price }}</span>
          <span class="line-price m-l-20 m-t-5" v-if="info.linePrice">￥{{ info.linePrice }}</span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">品牌：</span>
          <span>{{ info.brand }}</span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">产地：</span>
          <span>{{ info.productionPlace }}</span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">产品分类：</span>
          <span>{{ info.category }}</span>
        </div>
        <div v-if="false" class="flex flex-row-center m-t-15 font-size-18">
          <span class="min-w-120">卖点：</span>
          <span>
            卖点内容内卖点内容内卖点内容内卖点内容内卖点内容
          </span>
        </div>
        <div class="flex flex-row-center m-t-15 font-size-18" v-if="!(type === 'order')">
          <span class="min-w-120">库存数量：</span>
          <span>{{ info.warehouse }}</span>
          <span v-if="info.warehouse && info.qty">：</span>
          <span>{{ info.qty }}</span>
          <span v-if="info.productBatch">（生产批号：{{ info.productBatch }}）</span>
        </div>
        <div class="flex flex-row-center m-t-15" v-if="!(type === 'order')">
          <div class="flex1">
            <el-input-number
              v-model="orderQuantity"
              :min="info.minQty"
              :max="9999999"
              :step="Number(info.uQty)"
              size="small"
            ></el-input-number>
            <span v-if="Number(info.cartQty) > 0 || Number(info.minBuyQty) > 1" class="m-l-10 font-size-20">
              <span>（ </span>
              <span v-if="Number(info.cartQty) > 0" class="text-red">已添加：{{ info.cartQty }} </span>
              <span v-if="Number(info.minBuyQty) > 1" class="text-red">起订量：{{ info.minBuyQty }}</span>
              <span>）</span>
            </span>
            <div class="text-warning m-t-15 font-size-18">
              <span>{{ info.promotion }}</span>
              <span v-if="false">今日限购，当前可订货1</span>
            </div>
          </div>
        </div>
        <div class="btn-box" v-if="!(type === 'order')">
          <el-button @click="addShoppingCart" class="font-size-20 p-a-15" type="warning">
            加入购物车(Alt+F1)
          </el-button>
          <el-button @click="createOrder" class="font-size-20 p-a-15" type="primary">
            立即下单(F8)
          </el-button>
        </div>
      </div>
    </div>
    <div class="content-info">
      <el-tabs v-model="activeName">
        <el-tab-pane label="商品介绍" name="one">
          <!-- <el-descriptions ref="goodsdesc" class="margin-top" :column="2" border>
            <el-descriptions-item label="商品编码">
              {{ info.code || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="配件性质">
              {{ info.property || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="商品名称">
              {{ info.name || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="规格">
              {{ info.model || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="配件品牌">
              {{ info.brand || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="产地">
              {{ info.productionPlace || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="部位码">
              {{ info.posCode || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="配件分类">
              {{ info.category || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="生产码">
              {{ info.productionCode || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="车型">
              {{ info.vehModel || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="发动机型号">
              {{ info.engineNo || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="计量单位">
              {{ info.unit || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="长度">
              {{ info.length || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="宽度">
              {{ info.width || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="高度">
              {{ info.height || "— —" }}
            </el-descriptions-item>
            <el-descriptions-item label="体积">
              {{ info.volume || "— —" }}
            </el-descriptions-item>
            <template v-if="showMoreGoodsInfo">
              <el-descriptions-item label="重量">
                {{ info.weight || "— —" }}
              </el-descriptions-item>
              <el-descriptions-item label="备注">
                {{ info.remark || "— —" }}
              </el-descriptions-item>
            </template>
          </el-descriptions> -->
          <el-descriptions ref="goodsdesc" class="margin-top" :column="2" border>
            <template v-for="(item, index) in goodsIntroduceList">
              <el-descriptions-item :key="index" :label="item.rename || item.name" v-if="index <= rowCount * 2 - 1">
                {{ item.value || "— —" }}
              </el-descriptions-item>
              <el-descriptions-item
                :key="index"
                :label="item.rename || item.name"
                v-if="index > rowCount * 2 - 1 && showMoreGoodsInfo"
              >
                {{ item.value || "— —" }}
              </el-descriptions-item>
            </template>
          </el-descriptions>
          <div class="show-btns" v-if="showMoreBtn">
            <el-button size="small" type="text" @click="showMoreGoodsInfoFn">
              {{ showMoreGoodsInfo ? "点击收起" : "展开更多" }}
              <i class="el-icon-caret-top" v-if="showMoreGoodsInfo"></i>
              <i class="el-icon-caret-bottom" v-else></i>
            </el-button>
          </div>
          <div class="m-t-20 rich-text-content">
            <!-- 富文本内容 -->
          </div>
        </el-tab-pane>
        <el-tab-pane label="适用车型" name="two">
          <div class="flex flex-row-center">
            <span class="min-w-110">车型：</span>
            <span>{{ info.vehModel }}</span>
          </div>
          <div class="flex flex-row-center m-t-5">
            <span class="min-w-110">适用车型：</span>
            <span>{{ info.avaVehModel }}</span>
          </div>
          <div class="flex flex-row-center m-t-5">
            <span class="min-w-110">标准适用车型：</span>
          </div>
          <div class="m-t-10">
            <HtTable
              :data="tableData"
              :columns="columns"
              :table-name="tableName"
              :key-scope="tableName"
              :auto-height="true"
            />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <PlaceOrderType ref="placeOrderType" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getGoodsDetail, getOrderDetailsById } from "@/api/goods";
import { addShoppingCart } from "@/api/shoppingCart";
import PlaceOrderType from "@/components/PlaceOrderType";

export default {
  name: "ClientProductsDetails",
  components: {
    PlaceOrderType
  },
  data() {
    return {
      keyName: "alt+f1,f8",
      keyScope: "ProductsDetails",
      imgDefault: require("@/assets/img/no-goods-img.png"),
      desc: "浏览商品-商品详情",
      loading: false,
      dialogVisible: false,
      billingType: "one",
      buyer: "",
      buyerOptions: [{ id: 1, name: "买家1" }],
      orderQuantity: 1,
      activeName: "one",
      tableName: "clientProductsDetails",
      tableData: [],
      columns: [{ label: "车型名称", prop: "name" }],
      item: {},
      info: {},
      goodsIntroduceList: [], // 商品介绍
      rowCount: 10,
      type: null,
      showMoreGoodsInfo: false,
      showMoreBtn: false
    };
  },
  computed: {
    ...mapGetters("companyInfo", ["noLoginLook"])
  },
  mounted() {
    const query = this.$route.query;
    if (query && query.data) {
      this.item = JSON.parse(decodeURIComponent(query.data));
      this.getGoodsDetail();
    } else {
      // 从订单跳过来的数据 type:order
      this.type = this.$route.query.type;
      this.getOrderDetailsById();
    }

    setTimeout(() => {
      this.$hotkeys(this.keyName, this.keyScope, this.handleKeyCode);
      this.$hotkeys.setScope(this.keyScope);
    }, 500);
  },
  beforeDestroy() {
    this.$hotkeys.unbind(this.keyName, this.keyScope);
  },
  methods: {
    showMoreGoodsInfoFn() {
      this.showMoreGoodsInfo = !this.showMoreGoodsInfo;
    },
    handleKeyCode(e) {
      switch (e.code) {
        case "F1":
          e.preventDefault();
          e.stopPropagation();
          if (e.altKey) {
            this.addShoppingCart();
          }
          break;
        case "F8":
          e.preventDefault();
          e.stopPropagation();
          this.createOrder();
          break;
      }
    },
    getOrderDetailsById() {
      this.loading = true;
      getOrderDetailsById({ id: this.$route.query.id })
        .then(res => {
          const info = res || {};
          if (info.stdVehModels) {
            this.tableData = info.stdVehModels.map(item => ({ name: item }));
          }
          this.info = info;
          this.info.minBuyQty = Number(this.info.minBuyQty || 0);
          if (Number(this.info.minBuyQty) && Number(this.info.uQty)) {
            this.info.minQty = window.tool.getLCM(Number(this.info.minBuyQty), Number(this.info.uQty));
          } else {
            this.info.minQty = 1;
          }
          this.orderQuantity = this.info.minQty;

          const goodsBasicList = info.goodsBasic || [];
          const list = [];
          for (const item of goodsBasicList) {
            for (const key in info) {
              if (key == item.field) {
                const obj = {
                  ...item,
                  value: info[key]
                };
                list.push(obj);
              }
            }
          }
          this.goodsIntroduceList = list;
          this.isShowMoreBtnFn();
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "详情获取失败");
        });
    },
    getGoodsDetail() {
      const data = {
        goodsId: this.item.id,
        type: this.item.type,
        property: this.item.property,
        warehouse: this.item.warehouse,
        productBatch: this.item.productBatch
      };
      this.loading = true;
      getGoodsDetail(data)
        .then(res => {
          const info = res || {};
          if (info.stdVehModels) {
            this.tableData = info.stdVehModels.map(item => ({ name: item }));
          }
          this.info = info;
          this.info.minBuyQty = Number(this.info.minBuyQty || 1);
          if (Number(this.info.minBuyQty) && Number(this.info.uQty)) {
            this.info.minQty = window.tool.getLCM(Number(this.info.minBuyQty), Number(this.info.uQty));
          } else {
            this.info.minQty = 1;
          }
          this.orderQuantity = this.info.minQty;
          const goodsBasicList = info.goodsBasic;
          const list = [];
          for (const item of goodsBasicList) {
            for (const key in info) {
              if (key == item.field) {
                const obj = {
                  ...item,
                  value: info[key] + ""
                };
                list.push(obj);
              }
            }
          }
          this.goodsIntroduceList = list;
          this.isShowMoreBtnFn();

          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message || "详情获取失败");
        });
    },
    isShowMoreBtnFn() {
      // 超过 rowCount:10 行显示展示更多
      if (this.goodsIntroduceList.length > 2 * this.rowCount) {
        this.showMoreBtn = true;
      } else {
        this.showMoreBtn = false;
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    drawDown() {},
    claimCenter() {},
    addShoppingCart() {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      if (Number(this.orderQuantity) <= 0) {
        this.$message.warning("订货数量必须大于0");
        return;
      }
      const data = [
        {
          goodsType: this.info.type, // 商品类型，0:普通，1:补货，2:促销组合，3:服务项目，4:套餐
          goodsId: this.info.id, // 商品ID
          code: this.info.code, // 商品编码
          name: this.info.name, // 商品名称
          property: this.info.property, // 商品性质
          warehouse: this.info.warehouse, // 仓库
          productBatch: this.info.productBatch, // 生产批号
          price: this.info.price, // 加购时价格
          qty: this.orderQuantity, // 数量
          remarks: this.info.remarks, // 备注
          checked: false, // 是否已选择
          storeAreaDetailId: 0 // 商城区域详情ID，用来实时关联修理厂用
        }
      ];
      addShoppingCart({
        cover: false,
        data: data
      })
        .then(() => {
          this.$message.success("已成功加入购物车");
          this.$store.dispatch("user/getShoppingCartList");
          this.getGoodsDetail();
        })
        .catch(err => {
          this.$message.error(err.message || "加入购物车失败");
        });
    },
    createOrder() {
      if (this.noLoginLook) {
        this.$router.push({
          path: "/login"
        });
        return;
      }
      const data = window.tool.deepClone(this.info);
      data.qty = this.orderQuantity;
      this.$refs.placeOrderType.show({
        type: "productsDetails",
        data: data
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.client-products-details {
  overflow: auto !important;
  .content-title {
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
    background-color: #eeeeee;
  }
  .content-details {
    padding: 20px 10px;
    box-sizing: border-box;
    background-color: white;
  }
  .content-details-left {
    width: 400px;
    margin-right: 20px;
  }
  .content-details-right {
    position: relative;
    flex: 1;
    .max-out,
    .discount {
      color: white;
      font-size: 14px;
      padding: 2px 8px;
      background-color: #ff8400;
    }
    .discount {
      background-color: #e84615;
    }
    /deep/ .el-input__inner {
      font-size: 16px;
      font-weight: bolder;
    }
    .btn-box {
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }
  .content-info {
    padding: 0 10px;
    margin-bottom: 50px;
    border-top: 10px solid #eeeeee;
    /deep/ .el-descriptions-row {
      th {
        width: 100px;
        min-width: 100px;
        color: #000000;
        background-color: #eeeeee;
      }
      td {
        width: 1000px;
      }
    }
    /deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
      border: 1px solid rgba(153, 153, 153, 0.5);
    }
    .show-btns {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid rgba(153, 153, 153, 0.5);
      border-top: 0;
    }
    .rich-text-content {
      img {
        max-width: 100%;
      }
    }
  }
}
</style>
